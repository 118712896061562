import BaseLayout from "@components/BaseLayout"
import Blog from "@components/Blog"
import CategorySelect from "@components/CategorySelect"
import Hero from "@components/Hero"
import SearchInput from "@components/SearchInput"
import Seo from "@components/Seo"
import { graphql } from "gatsby"
import React from "react"
import { useSearch } from "../utils/hooks/useSearch"
import { useLocation } from "@reach/router"
import { useTranslate } from "@utils/hooks/useTranslate"

function BlogList({ data, pageContext }) {
  const allPosts = data?.allMarkdownRemark?.edges ?? []
  const metatags = data.metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )
  const { search: searchParams } = useLocation()
  const urlSearchParams = new URLSearchParams(searchParams)

  const {
    searchTerm,
    search,
    results: filteredResults,
    navigateToResultsList,
  } = useSearch(allPosts, data.siteSearchIndex.index, urlSearchParams.get("q"))
  const translate = useTranslate()
  const handleSearch = term => {
    search(term)
    navigateToResultsList(term)
  }

  return (
    <BaseLayout
      isDark
      hero={
        <Hero
          title="blog.hero.title"
          subtitle="blog.hero.subtitle"
          rightSide={
            <SearchInput
              placeholder={translate("blog.searchPlaceholder")}
              defaultValue={searchTerm}
              onSubmit={handleSearch}
              onChange={e => {
                handleSearch(e.target.value)
              }}
            />
          }
        />
      }
    >
      <CategorySelect categories={data?.allCategories?.data} />
      <Seo {...metatags} />
      <Blog
        {...{
          posts: searchTerm ? filteredResults : allPosts,
          pageContext,
        }}
      />
    </BaseLayout>
  )
}

export const query = graphql`
  query BlogList($postsLanguage: String!) {
    siteSearchIndex {
      index
    }
    metatag: markdownRemark(
      fileAbsolutePath: { glob: "**/content/metatags/blog.md" }
    ) {
      frontmatter {
        languages {
          language
          keywords
          title
          description
        }
      }
    }
    allCategories: allMarkdownRemark(
      filter: { frontmatter: { language: { eq: $postsLanguage } } }
    ) {
      data: distinct(field: frontmatter___category)
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/posts/*.md" }
        frontmatter: { language: { eq: $postsLanguage } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            description
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
          fields {
            slug
            categorySlug
          }
          timeToRead
        }
      }
    }
  }
`

export default BlogList
